import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {IPpOrder} from '../_generated/pp-order.interface';
import {PpOrder} from '../models/pp-order.model';
import {PpOrderList} from '../models/pp-order-list.model';
import {PpOrderServiceGENERATED} from '../_generated/pp-order-generated.service';
import { map } from 'rxjs/operators';
import {Observable} from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class PpOrderService extends PpOrderServiceGENERATED {

  public generateInvoicesBulk(orderParams: any): Observable<any> {
    return this.httpClient.post<IPpOrder>(
      this.getApiServiceRootUrl() + `/generate-orders-by-type`,
      orderParams,
      {headers: this.httpOptions}
    );
  }

  public generatePreviewByOrderId(orderId: number): Observable<any> {
    return this.httpClient.get(
      this.getApiServiceRootUrl() + `/generate-pdf-preview/${orderId}`,
      { responseType: 'blob' }
    )
  }

}
