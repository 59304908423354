import { Component, OnInit } from '@angular/core';
import {
  McGod, McInvoiceService,
  PpInvoiceList, PpOrder, PpOrderList, PpOrderService,
  SortCriteriaList,
  WebFile,
  WebFileService
} from "@miticon-ui/mc-core";
import {MkMatTableMenuAction, MkTableConfig} from "@miticon-ui/mc-components";
import {ActivatedRoute} from "@angular/router";
import {saveAs} from "file-saver";
import {HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";

@Component({
  selector: 'lib-mc-consumer-consumer-invoices-part',
  templateUrl: './mc-consumer-consumer-invoices-part.component.html',
  styleUrls: ['./mc-consumer-consumer-invoices-part.component.scss']
})
export class McConsumerConsumerInvoicesPartComponent implements OnInit {
  consumerId!: number;
  webFile!: WebFile;
  invoicesList = new PpInvoiceList();
  mcGod = McGod.getInstance();
  getAll$!: Observable<any>;

  errorMessage: string = '';

  //Table
  items = [];
  totalItemsCount = 0;
  pageNumber = 0;
  pageSize = 5;
  isLoading = false;
  tableConfig = new MkTableConfig();
  ppInvoiceList = new PpOrderList();
  mkMatMenuActionItems = [
    {
      title: McGod.t('cc.out-factoring.download'),
      matIcon: 'download',
      actionCd: 'Download',
      permission: McGod.PERM_MC_CONSUMER_VIEW
    }
  ];

  constructor(private webFileService: WebFileService,
              private invoiceService: McInvoiceService,
              private orderService: PpOrderService,
              private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.consumerId = this.route.snapshot.params['id'];
    this.initTableConfig();
    this.actLoad(0, 50);
  }

  initTableConfig() {
    this.tableConfig.addColumnStandard(McGod.t('pp.invoice.order-number'), 'orderNumber');
    this.tableConfig.addColumnStandard(McGod.t('pp.invoice.entity-name'), 'entityName');
    this.tableConfig.addColumnStandard(McGod.t('pp.invoice.entity-id'), 'idReceiverMcEntity');
    this.tableConfig.addColumnStandard(McGod.t('cc.table.recipient'), 'recipient');
    this.tableConfig.addColumnStandard(McGod.t('pp.invoice.total-amount'), 'fldTotalAmount()');
    this.tableConfig.addColumnStandard(McGod.t('pp.invoice.sending-date'), 'fldSendingDate()');
    this.tableConfig.addColumnStandard(McGod.t('pp.invoice.creation-date'), 'fldSysCreatedDate()');
    this.tableConfig.addColumnStandard(McGod.t('pp.invoice.type'), 'fldType()');
    this.tableConfig.addColumnStandard(McGod.t('pp.invoice.state'), 'fldState()');
  }

  actLoad(pageNumber: number, pageSize: number) {
    const sortCriteria = new SortCriteriaList();
    sortCriteria.addSort('id', 'DESC');

    let params = new HttpParams()
      .set("consumerId", this.consumerId)
      .set('page', pageNumber)
      .set('size', pageSize)

    this.ppInvoiceList.setPager(pageNumber, pageSize);

    this.loadInvoices(params);
  }

  onActionMkMatMenuItems($event: MkMatTableMenuAction) {
    switch ($event.action.actionCd) {
      case 'Download':
        if($event.item.state !== 'FAILED'){
          this.showInvoicePdf($event.item.id)
          this.errorMessage = ''
        }
        else{
          this.errorMessage = 'Cannot show invoice PDF for the invoice in status ' + $event.item.state + '.'
        }

        break;
    }
  }

  loadInvoices(params: HttpParams){
    this.getAll$ = this.invoiceService.getOrdersByFilter(params);

    this.getAll$.subscribe(
      response => {
        this.ppInvoiceList.items = response.content.map((object: any) => PpOrder.createFromJson(object));
        this.totalItemsCount = response.totalElements;
        this.pageSize = response.size;
      }
    )
  }

  showInvoicePdf(orderId: number){
    this.orderService.generatePreviewByOrderId(orderId).subscribe(
      (response) => {
        const blob = new Blob([response], {type: 'application/pdf'});
        const url = URL.createObjectURL(blob);
        const newWindow = window.open(url, '_blank');
        newWindow?.document.write(`<html><head><title>Invoice</title></head><body>
             <embed width="100%" height="100%" name="plugin" src="${url}"
            type="application/pdf" internalinstanceid="21"></body></html>`);
      }
    );
  }
}
