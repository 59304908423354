<span class="back-option" (click)="goBack()">
  <span [innerHTML]="iconBack | safeHtml"></span>
  {{ (consumerId ?  "cc.consumer360.back-option" : (isConsumer360 ? "cc.consumer360-profile.back-option" : "cc.consumer.back-to-overview")) | translate}}
</span>
<div class="cardbox">
  <span class="cardbox_title">
    {{ consumerId ? ('cc.consumer.overview.edit-consumer' | translate | uppercase) : ("cc.consumers.create.create-consumers" | translate | uppercase) }}
  </span>
</div>
<div class="cardbox">
  <form [formGroup]="form">
    <h2 class="title-medium-dark mb-large">{{'cc.consumer.overview.status-information' | translate}}</h2>
    <div class='input-wrapper radio_buttons'>
      <div class="radio_buttons_item">
        <h3>{{ "cc.jobs.edit.type" | translate }}*</h3>
        <div>
          <mat-radio-group formControlName="typeCd">
            <mat-radio-button [value]="'COMPANY'">{{'cc.consumers.edit.company' | translate}}</mat-radio-button>
            <mat-radio-button [value]="'PERSON'">{{'cc.consumers.edit.person' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="radio_buttons_item">
        <h3>{{ "cc.consumers.action.type.dunning" | translate }}</h3>
        <div>
          <mat-radio-group formControlName="flgDunningEnabled">
            <mat-radio-button [value]="false">{{'cc.common.edit.no' | translate}}</mat-radio-button>
            <mat-radio-button [value]="true">{{'cc.common.edit.yes' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div class="radio_buttons_item">
        <h3>{{ "cc.consumers.action.type.inkasso" | translate }}</h3>
        <div>
          <mat-radio-group formControlName="flgInkassoEnabled">
            <mat-radio-button [value]="false">{{'cc.common.edit.no' | translate}}</mat-radio-button>
            <mat-radio-button [value]="true">{{'cc.common.edit.yes' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <mat-form-field class="mt-extra-large" appearance="outline">
        <mat-label>{{'cc.common.edit.external-id' | translate}}</mat-label>
        <input type="text"
               (keydown)="onlyNumbersCheck($event)"
               formControlName="extConsumerId"
               matInput/>
      </mat-form-field>
    </div>

    <div class="divider"></div>

    <h2 class="title-medium-dark mb-large">{{'cc.common.basic-information' | translate}}</h2>
    <div class='input-wrapper'>
      <mat-form-field appearance="outline" *ngIf="isCompany()">
        <mat-label>{{'cc.common.company-name' | translate}}*</mat-label>
        <input type="text"
               formControlName="companyName"
               matInput/>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'cc.common.edit.first-name' | translate}}{{isRequired('firstName') ? '*' : ''}}</mat-label>
        <input type="text"
               formControlName="firstName"
               matInput/>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'cc.common.edit.last-name' | translate}}{{isRequired('lastName') ? '*' : ''}}</mat-label>
        <input type="text"
               formControlName="lastName"
               matInput/>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="isCompany()">
        <mat-label>{{'cc.common.edit.title' | translate}}</mat-label>
        <input type="text"
               formControlName="title"
               matInput/>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="!isCompany()">
        <mat-label>{{'cc.common.edit.birthday' | translate}}</mat-label>
        <input matInput [matDatepicker]="birthdayPicker" formControlName='birthday'
               [max]='maxDate' (click)='birthdayPicker.open()' readonly>
        <mat-datepicker-toggle matSuffix [for]="birthdayPicker"></mat-datepicker-toggle>
        <mat-datepicker #birthdayPicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field appearance="outline" *ngIf="!isCompany()">
        <mat-label>{{'cc.common.edit.gender' | translate}}</mat-label>
        <mat-select panelClass="matSelect"
                    formControlName="gender"
                    disableOptionCentering>
          <mat-option *ngFor="let gender of genders" [value]="gender.value">
            {{ gender.label | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'cc.product.view.vat' | translate}}</mat-label>
        <input type="text"
               (keydown)="onlyNumbersCheck($event)"
               formControlName="euVat"
               matInput/>
      </mat-form-field>
    </div>

    <div class="divider"></div>

    <h2 class="title-medium-dark mb-large">{{'cc.common.edit.address' | translate}}</h2>
    <div class='input-wrapper'>
      <mat-form-field appearance="outline">
        <mat-label>{{'cc.consumers.edit.street' | translate}}</mat-label>
        <input type="text"
               formControlName="street"
               matInput/>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'cc.consumers.edit.address-supplement' | translate}}</mat-label>
        <input type="text"
               formControlName="addressSupplement"
               matInput/>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'cc.consumers.edit.postal-code' | translate}}</mat-label>
        <input type="text"
               (keydown)="onlyNumbersCheck($event)"
               formControlName="postCode"
               matInput/>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'cc.common.edit.city' | translate}}</mat-label>
        <input type="text"
               formControlName="city"
               matInput/>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'cc.users.edit.country' | translate}}</mat-label>
        <input type="text"
               formControlName="countryName"
               matInput/>
      </mat-form-field>
    </div>

    <div class="divider"></div>

    <h2 class="title-medium-dark mb-large">{{'cc.consumer.overview.contact' | translate}}</h2>
    <div class='input-wrapper'>
      <mat-form-field appearance="outline">
        <mat-label>{{'cc.common.edit.email' | translate}}*</mat-label>
        <input type="email"
               formControlName="email"
               pattern='^[\w+-.]+@([\w-]+\.)+[\w-]{2,4}$'
               matInput/>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'cc.common.edit.telephone' | translate}}</mat-label>
        <input type="text"
               formControlName="telephone"
               matInput/>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'cc.consumers.edit.cellphone' | translate}}</mat-label>
        <input type="text"
               formControlName="cellphone"
               matInput/>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'cc.common.edit.fax' | translate}}</mat-label>
        <input type="text"
               formControlName="fax"
               matInput/>
      </mat-form-field>
    </div>

    <div class="divider"></div>

    <h2 class="title-medium-dark mb-large">{{'cc.users.edit.language' | translate}}</h2>
    <div class='input-wrapper'>
      <mat-form-field appearance="outline">
        <mat-label>{{'cc.consumers.edit.iso-country' | translate}}</mat-label>
        <mat-select panelClass="matSelect"
                    formControlName="isoCountry"
                    disableOptionCentering>
          <mat-option *ngFor="let county of isoCountries" [value]="county.value">
            {{ county.label | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'cc.consumers.edit.iso-language' | translate}}</mat-label>
        <mat-select panelClass="matSelect"
                    formControlName="isoLanguage"
                    disableOptionCentering>
          <mat-option *ngFor="let language of isoLanguages" [value]="language.value">
            {{ language.label | translate}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="divider"></div>

    <h2 class="title-medium-dark mb-large">{{'cc.common.bank-information' | translate}}</h2>
    <div class='input-wrapper'>
      <mat-form-field appearance="outline">
        <mat-label>{{'cc.consumers.edit.account-owner' | translate}}</mat-label>
        <input type="text"
               formControlName="owner"
               matInput/>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'cc.ebics.bank-name' | translate}}</mat-label>
        <input type="text"
               formControlName="bankName"
               matInput/>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'cc.common.iban' | translate}}</mat-label>
        <input type="text"
               formControlName="iban"
               matInput/>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'cc.consumers.edit.bic' | translate}}</mat-label>
        <input type="text"
               formControlName="bic"
               matInput/>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'cc.consumer.overview.sepa-mandate-id' | translate}}</mat-label>
        <input type="text"
               formControlName="sepaMandateId"
               matInput/>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>{{'cc.consumer.overview.sepa-mandate-date-of-signature' | translate}}</mat-label>
        <input matInput [matDatepicker]="sepaDatePicker"
               formControlName='sepaMandateDate'
               (click)='sepaDatePicker.open()' readonly>
        <mat-datepicker-toggle matSuffix [for]="sepaDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #sepaDatePicker></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="divider"></div>

    <h2 class="title-medium-dark mb-large">{{'cc.consumer.overview.additional-information' | translate}}</h2>

    <ng-container *ngIf="!isConsumer360">
      <h2 class="title-medium-blue mb-large">{{'cc.consumers.edit.contract-information' | translate}}</h2>
      <div class='input-wrapper'>
        <mat-form-field appearance="outline">
          <mat-label>{{'cc.common.contract-number' | translate}}</mat-label>
          <input type="text"
                 formControlName="contractNumber"
                 matInput/>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{'cc.common.contract-model' | translate}}</mat-label>
          <input type="text"
                 formControlName="contractModel"
                 matInput/>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{'cc.common.contract-type' | translate}}</mat-label>
          <input type="text"
                 formControlName="contractType"
                 matInput/>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{'cc.common.contract-status' | translate}}</mat-label>
          <input type="text"
                 formControlName="contractStatus"
                 matInput/>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{'cc.common.contract-signing-date' | translate}}</mat-label>
          <input matInput [matDatepicker]="contractSigningDatePicker"
                 formControlName='contractSigningDate'
                 (click)='contractSigningDatePicker.open()' readonly>
          <mat-datepicker-toggle matSuffix [for]="contractSigningDatePicker"></mat-datepicker-toggle>
          <mat-datepicker #contractSigningDatePicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{'cc.common.contract-start-date' | translate}}</mat-label>
          <input matInput [matDatepicker]="contractStartDatePicked"
                 formControlName='contractStartDate'
                 (click)='contractStartDatePicked.open()' readonly>
          <mat-datepicker-toggle matSuffix [for]="contractStartDatePicked"></mat-datepicker-toggle>
          <mat-datepicker #contractStartDatePicked></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{'cc.consumers.edit.contract-end-date' | translate}}</mat-label>
          <input matInput [matDatepicker]="contractEndDatePicked"
                 formControlName='contractEndDate'
                 (click)='contractEndDatePicked.open()' readonly>
          <mat-datepicker-toggle matSuffix [for]="contractEndDatePicked"></mat-datepicker-toggle>
          <mat-datepicker #contractEndDatePicked></mat-datepicker>
        </mat-form-field>
      </div>
      <div class='input-wrapper mt-large'>
        <mat-form-field appearance="outline">
          <mat-label>{{'cc.common.contract-base-amount' | translate}}</mat-label>
          <input type="text"
                 formControlName="contractBaseAmount"
                 matInput/>
        </mat-form-field>
        <mat-form-field style="width: 40%" appearance="outline">
          <mat-select panelClass="matSelect"
                      formControlName="currency"
                      disableOptionCentering>
            <mat-option *ngFor="let currency of currencies" [value]="currency.code">
              {{ currency.code | translate}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class='input-wrapper mt-large'>
        <mat-form-field appearance="outline">
          <mat-label>{{'cc.common.contract-cancellation-date' | translate}}</mat-label>
          <input matInput [matDatepicker]="contractCancellationDatePicked"
                 formControlName='contractCancellationDate'
                 (click)='contractCancellationDatePicked.open()' readonly>
          <mat-datepicker-toggle matSuffix [for]="contractCancellationDatePicked"></mat-datepicker-toggle>
          <mat-datepicker #contractCancellationDatePicked></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{'cc.contract.active-on-date' | translate}}</mat-label>
          <input matInput [matDatepicker]="contractCancellationActiveOnDate"
                 formControlName='contractCancellationActiveOnDate'
                 (click)='contractCancellationActiveOnDate.open()' readonly>
          <mat-datepicker-toggle matSuffix [for]="contractCancellationActiveOnDate"></mat-datepicker-toggle>
          <mat-datepicker #contractCancellationActiveOnDate></mat-datepicker>
        </mat-form-field>
      </div>
      <div class='mt-large'>
        <mat-form-field style="width: 100%" appearance="outline">
          <mat-label>{{'cc.common.contract-cancellation-reason' | translate}}</mat-label>
          <input type="text"
                 formControlName="contractCancellationReason"
                 matInput/>
        </mat-form-field>
      </div>
    </ng-container>

    <div *ngIf="csrConsumer && csrConsumer.entityConsumerAttributes && csrConsumer.entityConsumerAttributes.items" class="content-section">
      <h2 class="title-medium-blue mt-large mb-large">{{'cc.consumer.custom-info' | translate}}</h2>

      <div class='input-wrapper mt-large'>
        <ng-container *ngFor="let customAttribute of csrConsumer.entityConsumerAttributes.getFirstHalfOfItems()">
          <mcc-fi-textbox
            label="{{ customAttribute.name }}"
            name="{{ customAttribute.name }}"
            value="{{ csrConsumer.additionalAttributes ? csrConsumer.additionalAttributes[customAttribute.name] : '' }}"
            [mcForm]="mcForm"
            [newStyleInput]="true"></mcc-fi-textbox>
        </ng-container>
        <ng-container *ngFor="let customAttribute of csrConsumer.entityConsumerAttributes.getLastHalfOfItems()">
          <mcc-fi-textbox
            label="{{ customAttribute.name }}"
            name="{{ customAttribute.name }}"
            value="{{ csrConsumer.additionalAttributes ? csrConsumer.additionalAttributes[customAttribute.name] : '' }}"
            [mcForm]="mcForm"
            [newStyleInput]="true"></mcc-fi-textbox>
        </ng-container>
      </div>
    </div>

    <div class="content-footer">
      <button mat-button
              class="primary-btn"
              [disabled]="!form.valid"
              (click)="save()"
              type="submit">
        {{ (consumerId ? 'cc.common.view.save' : 'cc.common.create') | translate }}
      </button>
    </div>
  </form>
</div>
<br>
